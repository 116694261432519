import { Box, Link } from 'juhuui';
import MDtoJSX from 'markdown-to-jsx';
import React, { ReactNode } from 'react';

import Line from '../components/line';
import {
  HeadingMd,
  HeadingSm,
  ListBright,
  ListItem,
  TextLg,
  TextMd,
  TextSm,
} from '../styles';

interface MarkdownProps {
  children: ReactNode;
  smallList: boolean;
}

const H2 = (props: any) => <HeadingMd {...props} />;
const H3 = (props: any) => <HeadingSm css={{ pt: ['10'] }} {...props} />;

const H2reversed = (props: any) => (
  <HeadingSm css={{ pb: ['4'], pt: ['16'] }} {...props} />
);
const H3reversed = (props: any) => (
  <HeadingMd css={{ pb: ['4'], pt: ['6'] }} {...props} />
);
const Preversed = (props: any) => (
  <TextSm
    as="p"
    css={{
      pb: [4],
    }}
    {...props}
  />
);

const A = (props: any) => <Link css={{ c: 'tom.textHighlight' }} {...props} />;

const P = (props: any) => (
  <TextMd
    as="p"
    css={{
      pb: [4],
    }}
    {...props}
  />
);
const L = () => <Line css={{ mt: ['8'], mb: ['12'] }} />;
const Ul = (props: any) => (
  <ListBright
    css={{
      pb: [4],
      ml: [6],
    }}
    {...props}
  />
);
const UlSmall = (props: any) => (
  <ListBright
    css={{
      pb: [4],
      ml: [6],
      fontSize: ['sm', 'xs'],
    }}
    {...props}
  />
);
const Li = (props: any) => (
  <ListItem
    css={{
      w: ['calc(100% - var(var(--px)))', undefined, '90%'],
    }}
    {...props}
  />
);
const Blockquote = (props: any) => (
  <TextLg
    as="blockquote"
    css={{
      pb: [12],
      // c: 'tom.textHighlight'
    }}
    {...props}
  />
);
const Em = (props: any) => (
  <TextMd
    as="em"
    css={{ c: 'tom.textHighlight', fontStyle: 'normal' }}
    {...props}
  />
);

const Strong = (props: any) => (
  <TextMd
    as="em"
    css={{ c: 'tom.textHighlight', fontStyle: 'normal' }}
    {...props}
  />
);

export function Markdown({
  smallList = false,
  children,
  css,
  reverseHeadlines = false,
  ...props
}: MarkdownProps) {
  if (!children) return;

  return (
    <Box
      className="markdown"
      css={{
        whiteSpace: 'pre-wrap',
        hyphens: 'auto',
        ...css,
      }}
      {...props}
    >
      <MDtoJSX
        options={{
          forceBlock: true,
          overrides: {
            h2: !reverseHeadlines ? H2 : H2reversed,
            h3: !reverseHeadlines ? H3 : H3reversed,
            p: !reverseHeadlines ? P : Preversed,
            hr: L,
            ul: smallList ? UlSmall : Ul,
            ol: smallList ? UlSmall : Ul,
            li: Li,
            em: Em,
            strong: Strong,
            a: A,
            blockquote: Blockquote,
            // a: A
          },
        }}
      >
        {children}
      </MDtoJSX>
    </Box>
  );
}

export default Markdown;
